.promoModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;
  justify-content: center;
  align-items: center;

  &-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .bg-chinese-ny {
    background: url("../../../public/images/theme/chinese-new-year/bg-promo-chinese-ny-sm.png");
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-valentine {
    background: url("../../../public/images/theme/valentine/bg-promo-valentine-sm.png");
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-ramadhan {
    background: url("../../../public/images/theme/ramadhan/bg-promo-ramadhan-sm.png");
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-ramadhan-v2 {
    background: url("../../../public/images/theme/ramadhan-v2/bg-promo-sm.png");
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-kartini {
    background: url("../../../public/images/theme/kartini/bg-kartini-sm.png");
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-independence-day-v2 {
    background: url("../../../public/images/theme/independence-day-v2/bg-promo-sm.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-halloween {
    background: url("../../../public/images/theme/halloween/banner-mobile.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-christmas {
    background: url("../../../public/images/theme/christmas/bg-christmas-2024-sm.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: 640px) {
  .promoModal {
    .bg-chinese-ny {
      background: url("../../../public/images/theme/chinese-new-year/bg-promo-chinese-ny.png");
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-valentine {
      background: url("../../../public/images/theme/valentine/bg-promo-valentine.png");
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-ramadhan {
      background: url("../../../public/images/theme/ramadhan/bg-promo-ramadhan.png");
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-ramadhan-v2 {
      background: url("../../../public/images/theme/ramadhan-v2/bg-promo.png");
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-kartini {
      background: url("../../../public/images/theme/kartini/bg-kartini.png");
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-independence-day-v2 {
      background: url("../../../public/images/theme/independence-day-v2/bg-promo.png");
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-halloween {
      background: url("../../../public/images/theme/halloween/banner.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .bg-christmas {
      background: url("../../../public/images/theme/christmas/bg-christmas-2024.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
